
import sportsIcon from "../assets/imagesNew/icon-sport.png";
import casinoIcon from "../assets/imagesNew/icon-casino.png";
import slotIcon from "../assets/imagesNew/icon-slot.png";
import TableIcon from "../assets/imagesNew/icon-table.png";
import fishicon from "../assets/imagesNew/icon-fish.png";
import arcadeIcon from "../assets/imagesNew/icon-arcade.png";
import lotteryIcon from "../assets/imagesNew/icon-lottery.png";
import withdrawIcon from "../assets/imagesNew/withdraw.png";
import DepositIcon from "../assets/imagesNew/money.png";
import liveCasino1 from "../assets/imagesNew/live_casino1.webp";
import liveCasino2 from "../assets/imagesNew/live_casino2.webp";
import bannerSlider1 from "../assets/imagesNew/banner-slider1.jpg";
import bannerSlider2 from "../assets/imagesNew/banner-slider2.jpg";

import jilliIcon from "../assets/imagesNew/jilli.webp";
import Jili from "../assets/imagesNew/jilii.png";
import esport from "../assets/images/esport.webp";
import cashRocket from "../assets/images/cash-rocket.webp";
import sevenupsevendown from "../assets/images/7up7down.webp";
import sicbo from "../assets/images/sicbo.webp";
import cardmatka from "../assets/images/cardmatka.webp";

import aviator from "../assets/images/features/SPRIBE-EGAME-001.png";
import feature1 from "../assets/images/features/JILI-SLOT-027.png";
import feature2 from "../assets/images/features/JILI-SLOT-023.png";
import feature3 from "../assets/images/features/JILI-SLOT-049.png";
import feature4 from "../assets/images/features/JILI-SLOT-042.png";
import feature5 from "../assets/images/features/JILI-SLOT-076.png";
import feature6 from "../assets/images/features/JILI-SLOT-014.png";
import feature7 from "../assets/images/features/JILI-SLOT-031.png";
import feature8 from "../assets/images/features/JILI-SLOT-043.png";

import hotIcon from "../assets/images/icon-hot.gif";


import loginBanner1 from "../assets/images/login-banner1.webp";
import loginBanner2 from "../assets/images/login-banner2.webp";
import loginBanner3 from "../assets/images/login-banner3.webp";

import iconExchange from "../assets/images/icon-exchange.svg";
import iconSportbook from "../assets/images/icon-sportbook.svg";
import iconSboSport from "../assets/images/icon-sbov2.svg";
import iconHorsebook from "../assets/images/icon-horsebook.svg";
import iconVirtual from "../assets/images/icon-virtual.svg";
import iconKabaddi from "../assets/images/icon-kabaddi.svg";
import velkieLogo from "../assets/images/logo-velki.png";

import fc from "../assets/images/fc.png";
import jdb from "../assets/images/jdb.png";
import km from "../assets/images/km.png";
import ludo from "../assets/images/ludo.png";
import pg from "../assets/images/pg.png";
import pt from "../assets/images/pt.png";
import ps from "../assets/images/provider-ps.png";
import rt from "../assets/images/rt.png";
import sexy from "../assets/images/sexy.png";
import sg from "../assets/images/sg.png";
import venus from "../assets/images/venus.png";
import evo from "../assets/images/evo.png";
import sv from "../assets/images/sv.webp";
import pp from "../assets/images/pp.png";
import yl from "../assets/images/yl.png";
import yesbingo from "../assets/images/provider-awcmyesbingo.png";
import rich88 from "../assets/images/provider-rich88.png";
import ka from "../assets/images/provider-ka.png";
import cq9 from "../assets/images/provider-cq9.png";
import netent from "../assets/images/provider-netent.png";
import worldmatch from "../assets/images/provider-worldmatch.png";
import fastspinn from "../assets/images/provider-awcmfastspin.png";
import joker from "../assets/images/provider-joker.png";
import p8 from "../assets/images/provider-awcmp8.png";
import png from "../assets/images/provider-playngo.png";
import spribe from "../assets/images/provider-jdbaspribe.png";
import bpoker from "../assets/images/provider-bpoker.png";
import number from "../assets/images/provider-number.png";
import jdbIcon from "../assets/imagesNew/jdb.png";
import superAce from "../assets/imagesNew/superace.webp";
import moneyComing from "../assets/imagesNew/moneycoming.webp";
import fortuneGems from "../assets/imagesNew/fortune-gems.webp";

import ppIcon from "../assets/imagesNew/pp.webp";
import spadeIcon from "../assets/imagesNew/spade.png";

import evoNew from "../assets/images/evolution.webp";
import ezugiNew from "../assets/images/evolution-new.webp";
import jiliNew from "../assets/images/jililobby.webp";
import pragmaticNew from "../assets/images/pragmatic-play.webp";
import aeSexy from "../assets/images/aeSexy.webp";
import horseRacing from "../assets/images/horse-racing.gif";
import ptNew from "../assets/images/ptNew.jpg";
import bgNew from "../assets/images/BigG.jpg";

import fav1 from "../assets/images/fav1.jpg";
import fav2 from "../assets/images/fav2.jpg";
import fav3 from "../assets/images/fav3.jpg";
import fav4 from "../assets/images/fav4.jpg";

import pakistanFlag from "../assets/images/flag-symbolism-Pakistan-design-Islamic.png";
import camodiaFlag from "../assets/images/Flag-Cambodia.png";
import philippinesFlag from "../assets/images/Flag_of_philippines.svg.jpg";
import brazilFlag from "../assets/images/Flag_of_Brazil.svg.png";
import indiaFlag from "../assets/images/Flag_of_India.svg.png";
import bangladeshFlag from "../assets/images/Flag_of_Bangladesh.svg.png";
// hotgame Image
import pmegasicbo from "../assets/images/hot-casino/pmegasicbo.webp";
import pmegawheel from "../assets/images/hot-casino/pmegawheel.webp";
import pmegaroll from "../assets/images/hot-casino/pmegaroll.webp";
import pbaccarat from "../assets/images/hot-casino/pbaccarat.webp";
import pdt from "../assets/images/hot-casino/pdt.webp";
import ppsicbo from "../assets/images/hot-casino/ppsicbo.webp";
import pthilo from "../assets/images/hot-casino/pthilo.webp";
import ptspinwin from "../assets/images/hot-casino/ptspinwin.webp";
import playtechteenpatti from "../assets/images/hot-casino/playtech-teen-patti.webp";
import bgroll from "../assets/images/hot-casino/bgroll.webp";
import bgsicbo from "../assets/images/hot-casino/bgsicbo.webp";
import bgspeedsicbo from "../assets/images/hot-casino/bgspeedsicbo.webp";
import pcrazytime from "../assets/images/hot-casino/pcrazytime.webp";
import pmonopoly from "../assets/images/hot-casino/pmonopoly.webp";
import pcoinflip from "../assets/images/hot-casino/pcoinflip.webp";
import evoteenpatti from "../assets/images/hot-casino/evoteenpatti.webp";
import evosupersicbo from "../assets/images/hot-casino/evosupersicbo.webp";
import evospeedroullete from "../assets/images/hot-casino/evospeedroullete.webp";
import evolightdice from "../assets/images/hot-casino/evolightdice.webp";
import evoligtroullete from "../assets/images/hot-casino/evoligtroullete.webp";
import ppbacarrat2 from "../assets/images/hot-casino/ppbacarrat2.webp";

import hotgame028 from "../assets/images/hot-casino/KM-TABLE-028.webp";
import hotgame035 from "../assets/images/hot-casino/JILI-TABLE-035.webp";
import hotgame030 from "../assets/images/hot-casino/JILI-TABLE-030.webp";
import hotgame060 from "../assets/images/hot-casino/KM-TABLE-060.webp";

import hotgame047 from "../assets/images/hot-casino/JILI-TABLE-047.webp";
import hotgame015 from "../assets/images/hot-casino/KM-TABLE-015.webp";
import hotgame021 from "../assets/images/hot-casino/KM-TABLE-021.webp";
import hotgame022 from "../assets/images/hot-casino/KM-TABLE-022.webp";
import pgdragon from "../assets/images/hot-casino/pgdragon.webp";

const obj = {
  user_status: {
    owner: "OW",
    sub_owner: "SOW",
    super_admin: "SUA",
    admin: "AD",
    sub_admin: "SAD",
    senior_super: "SSM",
    super_agent: "SA",
    agent: "AG",
    user: "CL",
  },
  user_next_status: {
    owner: "sub_owner",
    sub_owner: "super_admin",
    super_admin: "admin",
    admin: "sub_admin",
    sub_admin: "senior_super",
    senior_super: "super_agent",
    super_agent: "agent",
    agent: "user",
  },
  market_category: {
    1: "Market",
    3: "Bookmakers",
    5: "ManualOdds",
    10: "Win Toss",
    2: "Session",
    6: "Over by Over Session Market",
    7: "Player",
    9: "Wicket",
    11: "Bowler Session",
    8: "Last Digit ODD",
    4: "LineMarket",
    14: "Premium ODDs",
  },
  betCheckObj: {
    4: "Cricket",
    2: "Tennis",
    1: "Soccer",
    3: "Casino",
  },
  matchType: {
    cricket: "Cricket",
    tennis: "Tennis",
    soccer: "Soccer",
    casino: "Casino",
  },
};
export default obj;
export const priceFormat = (value) => {
  return new Intl.NumberFormat().format(value);
};
export const LoginBanner = [
  { id: 1, banner: loginBanner1 },
  { id: 2, banner: loginBanner2 },
  { id: 3, banner: loginBanner3 },
];
export const CountryFlagBanner = [
  { id: 1, banner: pakistanFlag,title:"Pakistan" },
  { id: 2, banner: camodiaFlag ,title:"camodia"},
  { id: 3, banner: philippinesFlag ,title:"philippines"},
  { id: 4, banner: brazilFlag ,title:"brazil"},
  { id: 5, banner: indiaFlag,title:"india" },
  { id: 6, banner: bangladeshFlag ,title:"bangladesh"},
];
export const LiveCasinoData = [
  {
    id: 1,
    icons: liveCasino2,
    title: "AE SEXY",
    titlebn: "এই সেক্সি",
    gameTypeCheck: "platForm",
    platForm: "SEXYBCRT",
    gameType: "LIVE",
    casinoType: "MX-LIVE-002",
  },
  {
    id: 2,
    icons: liveCasino1,
    title: "WE",
    titlebn: "আমরা",
    platForm: "1006",
    gameType: "egtGame",
    gameTypeNew: "1",
    casinoType: "LIVE CASINO",
  },

  {
    icons: ezugiNew,
    title: "Ezugi",
    titlebn: "এজুগি",
    type: "ezugi",
    key: "live",
    gameType: "egtGame",
    platForm: "203",
    gameTypeNew: "1",
    casinoType: "LIVE CASINO",
  },
  {
    icons: bgNew,
    title: "BG",
    titlebn: "বি.জি",
    type: "bg",
    key: "live",
    gameTypeCheck: "platForm",
    platForm: "BG",
  },
  {
    icons: horseRacing,
    title: "HOUSE BOOK",
    titlebn: "হাউস বুক",
    type: "housebook",
    key: "live",
    gameTypeCheck: "platForm",
    platForm: "HORSEBOOK",
  },
  {
    icons: jiliNew,
    title: "JILI",
    titlebn: "জিলি",
    type: "jili",
    key: "live",
    gameTypeCheck: "platForm",
    platForm: "JILI",
  },
  {
    icons: pragmaticNew,
    title: "PP",
    titlebn: "পিপি",
    type: "pp",
    key: "live",
    gameTypeCheck: "platForm",
    platForm: "PP",
  },
  {
    icons: ptNew,
    title: "PT",
    titlebn: "পিটি",
    type: "pt",
    key: "live",
    gameTypeCheck: "platForm",
    platForm: "PT",
  },
  {
    icons: evoNew,
    title: "Evo",
    titlebn: "ইভো",
    type: "evo",
    key: "live",
    gameType: "egtGame",
    platForm: "1006",
    gameTypeNew: "1",
    casinoType: "LIVE CASINO",
  },
  {
    icons: aeSexy,
    title: "SEXY",
    titlebn: "সেক্সি",
    type: "sexy",
    key: "live",
    gameTypeCheck: "platForm",
    platForm: "SEXYBCRT",
  },
  {
    icons: sv,
    title: "SV388",
    titlebn: "এসভি৩৮৮",
    type: "sv",
    key: "live",
    gameTypeCheck: "platForm",
    platForm: "SV388",
  },
  {
    icons: venus,
    title: "VENUS",
    titlebn: "শুক্র",
    type: "venus",
    key: "live",
    gameTypeCheck: "platForm",
    platForm: "VENUS",
  },
];
export const BannerSliderData = [
  { id: 1, bannerImg: bannerSlider1 },
  { id: 2, bannerImg: bannerSlider2 },
];
export const SlotData = [
  {
    id: 1,
    icons: aviator,
    title: "Aviator",
    titlebn: "বৈমানিক",
    gameTypeCheck: "platForm",
    gameType: "EGAME",
    platForm: "SPRIBE",
    casinoType:"SPRIBE-EGAME-001"
  },
  {
    id: 1,
    icons: feature1,
    title: "Super Ace",
    titlebn: "সুপার এস",
    gameTypeCheck: "platForm",
    gameType: "SLOT",
    platForm: "JILI",
    casinoType:"JILI-SLOT-027"
  },
  {
    id: 2,
    icons: feature2,
    title: "Golden Bank",
    titlebn: "গোল্ডেন ব্যাংক",
    gameTypeCheck: "platForm",
    gameType: "SLOT",
    platForm: "JDB",
    casinoType:"JILI-SLOT-023"
  },
  {
    id: 3,
    icons: feature3,
    title: "Alibaba",
    titlebn: "আলিবাবা",
    gameTypeCheck: "platForm",
    gameType: "SLOT",
    platForm: "JILI",
    casinoType:"JILI-SLOT-049"

  },
  {
    id: 4,
    icons: feature4,
    title: "Golden Empire",
    titlebn: "গোল্ডেন সাম্রাজ্য",
    gameTypeCheck: "platForm",
    gameType: "SLOT",
    platForm: "JILI",
    casinoType:"JILI-SLOT-042"
  },
  {
    id: 5,
    icons: feature5,
    title: "Fortune Gems 2",
    titlebn: "ভাগ্য রত্ন 2",
    gameTypeCheck: "platForm",
    gameType: "SLOT",
    platForm: "JILI",
    casinoType:'JILI-SLOT-076'
  },{
    id: 6,
    icons: feature6,
    title: "Crazy777",
    titlebn: "পাগল777",
    gameTypeCheck: "platForm",
    gameType: "SLOT",
    platForm: "JILI",
    casinoType:"JILI-SLOT-014"
  },{
    id: 5,
    icons: feature7,
    title: "Boxing King",
    titlebn: "বক্সিং কিং",
    gameTypeCheck: "platForm",
    gameType: "SLOT",
    platForm: "JILI",
    casinoType:"JILI-SLOT-031"
  },{
    id: 5,
    icons: feature5,
    title: "Fortune Gems",
    titlebn: "ভাগ্য রত্ন",
    gameTypeCheck: "platForm",
    gameType: "SLOT",
    platForm: "JILI",
    casinoType:'JILI-SLOT-043'
  },
];

export const TableEgameData = [
  {
    id: 1,
    icons: fav1,
    link:""
  },
  {
    id: 2,
    icons: fav2,
    link:""
  },
  {
    id: 3,
    icons: fav3,
    link:""
    
  }, {
    id: 3,
    icons: fav4,
    link:""
    
  },
  
];
export const DepositWithrawDataNew = [
  {
    id: 1,
    icons: DepositIcon,
    title: "Deposit",
    link: "/deposit",
    titlebn: "জমা",
  },
  {
    id: 2,
    icons: withdrawIcon,
    title: "Withdraw",
    link: "/withdraw",
    titlebn: "প্রত্যাহার করুন",
  },
];
export const DepositWithrawData = [
  {
    id: 1,
    icons: hotIcon,
    title: "Hot",
    type: "hotgame",
    titlebn: "গরম খেল",
  },
  {
    id: 1,
    icons: sportsIcon,
    title: "Sports",
    type: "sports",
    titlebn: "বিনিময়",
  },
  {
    id: 2,
    icons: casinoIcon,
    title: "Casino",
    titlebn: "লাইভ ক্যাসিনো",
    type: "casino",
  },
  { id: 3, 
    icons: slotIcon,
     title: "Slot",
      titlebn: "স্লট",
       type: "slot" 
      },
  { id: 4, 
    icons:TableIcon,
    title: "Table",
    titlebn: "টেবিল",
    type: "table" 
  },
  {
    id: 5,
    icons: fishicon,
    title: "Fishing",
    titlebn: "মাছ ধরা",
    type: "fish",
  },
  {
    id: 5,
    icons: arcadeIcon,
    title: "Arcade",
    type: "arcade",
    titlebn: "স্পোর্টসবুক",
  },
  
  {
    id: 6,
    icons: lotteryIcon,
    title: "Lottery",
    titlebn: "খেলাধুলা",
    type: "lottery",
  },
  
];
export const DepositWithrawDataArray = [
  {
    id: 1,
    title: "Hot",
    key: "hotgame",
    titlebn: "গরম খেল",
    array: [
      
      {
        id: 1,
        image: hotgame030,
        title: "Go Rush",
        type: "Go Rush",
        gameTypeCheck: "platForm",
        platForm: "JILI",
        gameType: "TABLE",
        casinoType: "JILI-TABLE-030",
      },
      {
        id: 2,
        image: hotgame047,
        title: "Crash Bonus",
        type: "Crash Bonus",
        gameTypeCheck: "platForm",
        platForm: "JILI",
        gameType: "TABLE",
        casinoType: "JILI-TABLE-047",
      },
      {
        id: 3,
        image: hotgame035,
        title: "Limbo",
        type: "Limbo",
        gameTypeCheck: "platForm",
        platForm: "JILI",
        gameType: "TABLE",
        casinoType: "JILI-TABLE-035",
      },
      {
        id: 4,
        image: hotgame021,
        title: "Sugar Blast",
        type: "Sugar Blast",
        gameTypeCheck: "platForm",
        platForm: "KINGMAKER",
        gameType: "SLOT",
        casinoType: "KM-SLOT-001",
      },
      {
        id: 5,
        image: hotgame015,
        title: "Sicbo",
        type: "Sicbo",
        gameTypeCheck: "platForm",
        platForm: "KINGMAKER",
        gameType: "TABLE",
        casinoType: "KM-TABLE-015",
      },
    
      {
        id: 6,
        image: hotgame021,
        title: "Matka",
        type: "Matka",
        gameTypeCheck: "platForm",
        platForm: "KINGMAKER",
        gameType: "TABLE",
        casinoType: "KM-TABLE-021",
      },
      {
        id:7,
        image: hotgame022,
        title: "Card Matka",
        type: "Card Matka",
        gameTypeCheck: "platForm",
        platForm: "KINGMAKER",
        gameType: "TABLE",
        casinoType: "KM-TABLE-022",
      },
      {
        id: 8,
        image: hotgame028,
        title: "7 Up 7 Down",
        type: "7 Up 7 Down",
        gameTypeCheck: "platForm",
        platForm: "KINGMAKER",
        gameType: "TABLE",
        casinoType: "KM-TABLE-028",
      },
      {
        id: 9,
        image: hotgame060,
        title: "Ludo",
        type: "Ludo",
        gameTypeCheck: "platForm",
        platForm: "KINGMAKER",
        gameType: "TABLE",
        casinoType: "KM-TABLE-060",
      },
      {
        id: 10,
        image: pgdragon,
        title: "Dragon ",
        type: "Dragon Tiger",
        gameTypeCheck: "platForm",
        platForm: "PG",
        gameType: "SLOT",
        casinoType: "PG-SLOT-035",
      },

    ],
  },
  {
    id: 1,
    title: "Sports",
    key: "sports",
    titlebn: "বিনিময়",
    array: [
      {
        image: iconExchange,
        title: "Exchange",
        gameTypeCheck: "sports",
        link: `${process.env.REACT_APP_SKY_URL}bkash/${localStorage.getItem(
          "token"
        )}`,
      },
      {
        image: velkieLogo,
        title: "Velki",
        gameTypeCheck: "sports",
        link: `${process.env.REACT_APP_VELKIE_URL}bkash/${localStorage.getItem(
          "token"
        )}`,
      },
      {
        image: iconSportbook,
        title: "I-Sports",
        link: "",
        gameTypeCheck: "platForm",
        platForm: "SABA",
        gameType: "VIRTUAL",
        casinoType: "SABA-VIRTUAL-001",
      },
      // {
      //   image: iconSboSport,
      //   title: "SBO Sports",
      //   link: "",
      //   gameTypeCheck: "platForm",
      //   platForm: "SABA",
      //   gameType: "VIRTUAL",
      //   casinoType: "SABA-VIRTUAL-001",
      // },
      {
        image: iconHorsebook,
        title: "Horsebook",
        link: "",
        gameTypeCheck: "platForm",
        platForm: "HORSEBOOK",
        gameType: "LIVE",
        casinoType: "HRB-LIVE-001",
      },
      // {
      //   image: iconVirtual,
      //   title: "Virtual",
      //   link: "",
      //   gameTypeCheck: "platForm",
      //   platForm: "SABA",
      //   gameType: "VIRTUAL",
      //   casinoType: "SABA-VIRTUAL-001",
      // },
      // {
      //   image: iconKabaddi,
      //   title: "Kabaddi",
      //   link: "",
      //   gameTypeCheck: "platForm",
      //   platForm: "SABA",
      //   gameType: "VIRTUAL",
      //   casinoType: "SABA-VIRTUAL-001",
      // },
    ],
  },
  {
    id: 2,
    title: "Casino",
    key: "casino",
    titlebn: "লাইভ ক্যাসিনো",
    array: [
      {
        image: evo,
        title: "Evo",
        type: "evo",
        key: "live",
        gameTypeCheck: "platForm",
        platForm: "EVOLUTION",
        gameType: "LIVE",
        casinoType: "EVOLUTION-LIVE-006",
        isLobbyTrue:true
      },
      {
        image: sexy,
        title: "SEXY",
        type: "sexy",
        key: "live",
        gameTypeCheck: "platForm",
        platForm: "SEXYBCRT",
        gameType: "LIVE",
        casinoType: "MX-LIVE-002",
      },
      {
        image: pp,
        title: "PP",
        type: "pp",
        key: "live",
        gameTypeCheck: "platForm",
        platForm: "PP",
        gameType: "LIVE",
        casinoType: "PP-LIVE-001",
        isLobbyTrue:true
      },
      {
        image: pt,
        title: "PT",
        type: "pt",
        key: "live",
        gameTypeCheck: "platForm",
        platForm: "PT",
      },
      {
        image: venus,
        title: "VENUS",
        type: "venus",
        key: "live",
        gameTypeCheck: "platForm",
        platForm: "VENUS",
      },
      // {
      //   image: evo,
      //   title: "Ezugi",
      //   type: "ezugi",
      //   key: "live",
      //   gameType: "egtGame",
      //   platForm: "203",
      //   gameTypeNew: "1",
      //   casinoType: "LIVE CASINO",
      // },
      // {
      //   image: bg,
      //   title: "BG",
      //   type: "bg",
      //   key: "live",
      //   gameTypeCheck: "platForm",
      //   platForm: "BG",
      //   gameType: "LIVE",
      //   casinoType: "BG-LIVE-001",
      // },
      {
        image: iconHorsebook,
        title: "HOUSE BOOK",
        type: "housebook",
        key: "live",
        gameTypeCheck: "platForm",
        platForm: "HORSEBOOK",
        gameType: "LIVE",
        casinoType: "HRB-LIVE-001",
      },
      // {
      //   image: Jili,
      //   title: "JILI",
      //   type: "jili",
      //   key: "live",
      //   gameTypeCheck: "platForm",
      //   platForm: "JILI",
      // },
      
      
     
      {
        image: sv,
        title: "SV388",
        type: "sv",
        key: "live",
        gameTypeCheck: "platForm",
        platForm: "SV388",
      },
      
    ],
  },
  {
    id: 2,
    title: "Slot",
    key: "slot",
    titlebn: "স্লট",
    array: [
      {
        image: Jili,
        title: "JILI",
        type: "jili",
        key: "slot",
        gameTypeCheck: "platForm",
        gameType: "SLOT",
        platForm: "JILI",
      },
      {
        image: jdb,
        title: "JDB",
        type: "jdb",
        key: "slot",
        gameTypeCheck: "platForm",
        gameType: "SLOT",
        platForm: "JDB",
      },
      {
        image: pg,
        title: "PG",
        type: "pg",
        key: "slot",
        gameTypeCheck: "platForm",
        gameType: "SLOT",
        platForm: "PG",
      },
      {
        image: sg,
        title: "SG",
        type: "spade",
        key: "slot",
        gameTypeCheck: "platForm",
        gameType: "SLOT",
        platForm: "SPADE",
      },
      {
        image: rt,
        title: "RT",
        type: "rt",
        key: "slot",
        gameTypeCheck: "platForm",
        gameType: "SLOT",
        platForm: "FC",
      },
      {
        image: fc,
        title: "FC",
        type: "fc",
        key: "slot",
        gameTypeCheck: "platForm",
        gameType: "SLOT",
        platForm: "FC",
      },
    
      {
        image: pp,
        title: "PP",
        type: "pp",
        key: "slot",
        gameTypeCheck: "platForm",
        gameType: "SLOT",
        platForm: "PP",
      },
      // {
      //   image: ps,
      //   title: "PLAYSTAR",
      //   type: "PLAYSTAR",
      //   key: "slot",
      //   gameTypeCheck: "platForm",
      //   gameType: "SLOT",
      //   platForm: "PP",
      //   casinoType: "PP-SLOT-001",
      // },
      {
        image: pt,
        title: "PT",
        type: "pt",
        key: "slot",
        gameTypeCheck: "platForm",
        gameType: "SLOT",
        platForm: "PT",
      },
      // {
      //   image: rich88,
      //   title: "RICH88",
      //   type: "RICH88",
      //   key: "slot",
      //   gameTypeCheck: "platForm",
      //   gameType: "SLOT",
      //   platForm: "PP",
      //   casinoType: "PP-SLOT-001",
      // },
      // {
      //   image: ka,
      //   title: "KA",
      //   type: "KA",
      //   key: "slot",
      //   gameTypeCheck: "platForm",
      //   gameType: "SLOT",
      //   platForm: "JDB",
      // },
      // {
      //   image: cq9,
      //   title: "CQ9",
      //   type: "CQ9",
      //   key: "slot",
      //   gameTypeCheck: "platForm",
      //   gameType: "SLOT",
      //   platForm: "FC",
      //   casinoType: "FC-SLOT-001",
      // },
      // {
      //   image: netent,
      //   title: "NETENT",
      //   type: "NETENT",
      //   key: "slot",
      //   gameTypeCheck: "platForm",
      //   gameType: "SLOT",
      //   platForm: "FC",
      //   casinoType: "FC-SLOT-002",
      // },{
      //   image: worldmatch,
      //   title: "WorldMatch",
      //   type: "WorldMatch",
      //   key: "slot",
      //   gameTypeCheck: "platForm",
      //   gameType: "SLOT",
      //   platForm: "JDB",
      //   casinoType: "JDB-SLOT-001",
      // },
     
      // {
      //   image: fastspinn,
      //   title: "FastSpin",
      //   type: "FastSpin",
      //   key: "slot",
      //   gameTypeCheck: "platForm",
      //   gameType: "SLOT",
      //   platForm: "FASTSPIN",
      // },
      // {
      //   image: joker,
      //   title: "Joker",
      //   type: "Joker",
      //   key: "slot",
      //   gameTypeCheck: "platForm",
      //   gameType: "SLOT",
      //   platForm: "JDB",
      //   casinoType: "JDB-SLOT-002",
      // },
      // {
      //   image: p8,
      //   title: "P8",
      //   type: "P8",
      //   key: "slot",
      //   gameTypeCheck: "platForm",
      //   gameType: "SLOT",
      //   platForm: "PP",
      //   casinoType: "PP-SLOT-003",
      // },
      // {
      //   image: png,
      //   title: "PNG",
      //   type: "PNG",
      //   key: "slot",
      //   gameTypeCheck: "platForm",
      //   gameType: "SLOT",
      //   platForm: "JDB",
      //   casinoType: "JDB-SLOT-009",
        
      // },
      
      {
        image: yesbingo,
        title: "Yes Bingo",
        type: "yesbingo",
        key: "slot",
        gameTypeCheck: "platForm",
        gameType: "SLOT",
        platForm: "YESBINGO",
      },
      {
        image: yl,
        title: "YL",
        type: "yl",
        key: "slot",
        gameTypeCheck: "platForm",
        gameType: "SLOT",
        platForm: "YL",
      },
    ],
  },
  {
    id: 3,
    title: "Table",
    key: "table",
    titlebn: "টেবিল",
    array: [
      
      // {
      //   image: spribe,
      //   title: "SPRIBE",
      //   type: "SPRIBE",
      //   key: "table",
      //   gameTypeCheck: "platForm",
      //   gameType: "TABLE",
      //   platForm: "JILI",
      //   casinoType: "JILI-TABLE-003",
      // },
      {
        image: Jili,
        title: "JILI",
        type: "jili",
        key: "table",
        gameTypeCheck: "platForm",
        gameType: "TABLE",
        platForm: "JILI",
      },
      {
        image: pg,
        title: "PG",
        type: "pg",
        key: "slot",
        gameTypeCheck: "platForm",
        gameType: "SLOT",
        platForm: "PG",
      },
      // {
      //   image: png,
      //   title: "PNG",
      //   type: "PNG",
      //   key: "slot",
      //   gameTypeCheck: "platForm",
      //   gameType: "TABLE",
      //   platForm: "JILI",
      //   casinoType: "JILI-TABLE-004",
      // },{
      //   image: rich88,
      //   title: "RICH88",
      //   type: "RICH88",
      //   key: "slot",
      //   gameTypeCheck: "platForm",
      //   gameType: "SLOT",
      //   platForm: "PP",
      // },
      {
        image: km,
        title: "KM",
        type: "kingmaker",
        key: "table",
        gameTypeCheck: "platForm",
        gameType: "TABLE",
        platForm: "KINGMAKER",
      },   
      // {
      //   image: ka,
      //   title: "KA",
      //   type: "KA",
      //   key: "slot",
      //   gameTypeCheck: "platForm",
      //   gameType: "TABLE",
      //   platForm: "KINGMAKER",
      //   casinoType: "KINGMAKER-TABLE-004",
        
      // },{
      //   image: worldmatch,
      //   title: "WorldMatch",
      //   type: "WorldMatch",
      //   key: "slot",
      //   gameTypeCheck: "platForm",
      //   gameType: "TABLE",
      //   platForm: "KINGMAKER",
      //   casinoType: "KINGMAKER-TABLE-005",
      // },{
      //   image: cq9,
      //   title: "CQ9",
      //   type: "CQ9",
      //   key: "slot",
      //   gameTypeCheck: "platForm",
      //   gameType: "TABLE",
      //   platForm: "KINGMAKER",
      //   casinoType: "KINGMAKER-TABLE-006",
      // },{
      //   image: bpoker,
      //   title: "Bpoker",
      //   type: "bpoker",
      //   key: "slot",
      //   gameTypeCheck: "platForm",
      //   gameType: "TABLE",
      //   platForm: "JILI",
      // },
      {
        image: ludo,
        title: "LUDO",
        type: "ludo",
        key: "table",
        gameTypeCheck: "platForm",
        gameType: "TABLE",
        platForm: "LUDO",
      },
      {
        image: sevenupsevendown,
        title: "7up7Down",
        type: "7up7down",
        key: "table",
        gameTypeCheck: "platForm",
        gameType: "TABLE",
        platForm: "KINGMAKER",
        casinoType: "KM-TABLE-028",
      },
      {
        image: sicbo,
        title: "Sicbo",
        type: "sicbo",
        key: "table",
        gameTypeCheck: "platForm",
        gameType: "TABLE",
        platForm: "KINGMAKER",
        casinoType: "KM-TABLE-015",
      },
      {
        image: cardmatka,
        title: "Matka",
        type: "matka",
        key: "table",
        gameTypeCheck: "platForm",
        gameType: "TABLE",
        platForm: "KINGMAKER",
        casinoType: "KM-TABLE-022",
      },
      
    ],
  },
  {
    id: 4,
    title: "Fishing",
    key: "fish",
    type: "fish",
    array: [
      {
        image: Jili,
        title: "JILI",
        type: "jili",
        key: "fish",
        gameTypeCheck: "platForm",
        gameType: "FH",
        platForm: "JILI",
      },
      {
        image: fc,
        title: "FC",
        type: "fc",
        key: "fish",
        gameTypeCheck: "platForm",
        gameType: "FH",
        platForm: "FC",
      },
      // {
      //   image: ka,
      //   title: "KA",
      //   type: "KA",
      //   key: "slot",
      //   gameTypeCheck: "platForm",
      //   gameType: "FH",
      //   platForm: "FC",
      //   casinoType:"FC-FISH-001"
        
      // },
      {
        image: jdb,
        title: "JDB",
        type: "jdb",
        key: "fish",
        gameTypeCheck: "platForm",
        gameType: "FH",
        platForm: "JDB",
      },
      // {
      //   image: ps,
      //   title: "PLAYSTAR",
      //   type: "PLAYSTAR",
      //   key: "slot",
      //   gameTypeCheck: "platForm",
      //   gameType: "SLOT",
      //   platForm: "PP",
      // },{
      //   image: cq9,
      //   title: "CQ9",
      //   type: "CQ9",
      //   key: "slot",
      //   gameTypeCheck: "platForm",
      //   gameType: "FH",
      //   platForm: "JDB",
      //   casinoType: "JDB-FISH-009",
      // },
      // {
      //   image: joker,
      //   title: "Joker",
      //   type: "Joker",
      //   key: "slot",
      //   gameTypeCheck: "platForm",
      //   gameType: "FH",
      //   platForm: "JDB",
      //   casinoType: "JDB-FISH-010",
      // },
      // {
      //   image: p8,
      //   title: "P8",
      //   type: "P8",
      //   key: "slot",
      //   gameTypeCheck: "platForm",
      //   gameType: "FH",
      //   platForm: "JDB",
      //   casinoType: "JDB-FISH-009",
      // },
      {
        image: yl,
        title: "YL",
        type: "yl",
        key: "slot",
        gameTypeCheck: "platForm",
        gameType: "FH",
        platForm: "YL",
      },
      {
        image: sg,
        title: "Spade",
        type: "spade",
        key: "slot",
        gameTypeCheck: "platForm",
        gameType: "SLOT",
        platForm: "SPADE",
      },
      {
        image: yesbingo,
        title: "Yes Bingo",
        type: "yesbingo",
        key: "fish",
        gameTypeCheck: "platForm",
        gameType: "FH",
        platForm: "YESBINGO",
      }
    ],
  },
  
  {
    id: 6,
    title: "Arcade",
    key: "arcade",
    titlebn: "স্পোর্টসবুক",
    array: [
      {
        image: esport,
        title: "E1sports",
        type: "e1sports",
        key: "esports",
        gameTypeCheck: "platForm",
        gameType: "EGAME",
        platForm: "FC",
      },
      // {
      //   image: rich88,
      //   title: "RICH88",
      //   type: "RICH88",
      //   key: "slot",
      //   gameTypeCheck: "platForm",
      //   gameType: "SLOT",
      //   platForm: "PP",
      // },{
      //   image: cq9,
      //   title: "CQ9",
      //   type: "CQ9",
      //   key: "slot",
      //   gameTypeCheck: "platForm",
      //   gameType: "FH",
      //   platForm: "JDB",
      //   casinoType: "JDB-FISH-009",
      // },
      // {
      //   image: pp,
      //   title: "PP",
      //   type: "pp",
      //   key: "slot",
      //   gameTypeCheck: "platForm",
      //   gameType: "SLOT",
      //   platForm: "PP",
      // },
      {
        image: fc,
        title: "FC",
        type: "fc",
        key: "fish",
        gameTypeCheck: "platForm",
        gameType: "EGAME",
        platForm: "FC",
      },
      {
        image: yl,
        title: "YL",
        type: "yl",
        key: "slot",
        gameTypeCheck: "platForm",
        gameType: "EGAME",
        platForm: "YL",
      },
      {
        image: sg,
        title: "Spade",
        type: "spade",
        key: "slot",
        gameTypeCheck: "platForm",
        gameType: "EGAME",
        platForm: "SPADE",
      },
      // {
      //   image: ps,
      //   title: "PLAYSTAR",
      //   type: "PLAYSTAR",
      //   key: "slot",
      //   gameTypeCheck: "platForm",
      //   gameType: "SLOT",
      //   platForm: "PP",
      // },
      // {
      //   image: ka,
      //   title: "KA",
      //   type: "KA",
      //   key: "slot",
      //   gameTypeCheck: "platForm",
      //   gameType: "FH",
      //   platForm: "JDB",
      //   casinoType: "JDB-FISH-011",
      // },
    ],
  },
  {
    id: 7,
    title: "Lottery",
    key: "lottery",
    titlebn: "খেলাধুলা",
    array: [
      {
        image: km,
        title: "KM",
        type: "kingmaker",
        key: "table",
        gameTypeCheck: "platForm",
        gameType: "TABLE",
        platForm: "KINGMAKER",
      },{
        image: number,
        title: "Money",
        type: "number",
        key: "table",
        gameTypeCheck: "platForm",
        gameType: "SLOT",
        platForm: "JILI",
        casinoType:"JILI-SLOT-029"
      },{
        image: Jili,
        title: "JILI",
        type: "jili",
        key: "fish",
        gameTypeCheck: "platForm",
        gameType: "FH",
        platForm: "JILI",
      }
      ,{
        image: cashRocket,
        title: "Rocket",
        type: "rocket",
        key: "table",
        gameTypeCheck: "platForm",
        gameType: "TABLE",
        platForm: "KINGMAKER",
        casinoType:"KM-TABLE-041"
      },

      
      // {
      //   image: joker,
      //   title: "Joker",
      //   type: "Joker",
      //   key: "slot",
      //   gameTypeCheck: "platForm",
      //   gameType: "FH",
      //   platForm: "JDB",
      //   casinoType: "JDB-FISH-009",
      // },
      
      {
        image: yesbingo,
        title: "Yes Bingo",
        type: "yesbingo",
        key: "fish",
        gameTypeCheck: "platForm",
        gameType: "FH",
        platForm: "YESBINGO",
      }
      
    ],
  },
];
export const HotCasinoData = [
 
  {
    id: 11,
    icons: pmegasicbo,
    title: "Mega Sic Bo",
    titlebn: "এই সেক্সি",
    gameTypeCheck: "platForm",
    platForm: "PP",
    gameType: "LIVE",
    casinoType: "PP-LIVE-025",
  },
  {
    id: 12,
    icons: pmegawheel,
    title: "Mega Wheel",
    titlebn: "এই সেক্সি",
    gameTypeCheck: "platForm",
    platForm: "PP",
    gameType: "LIVE",
    casinoType: "PP-LIVE-038",
  },
  {
    id: 13,
    icons: pmegaroll,
    title: "Mega Roulette",
    titlebn: "এই সেক্সি",
    gameTypeCheck: "platForm",
    platForm: "PP",
    gameType: "LIVE",
    casinoType: "PP-LIVE-039",
  },
  {
    id: 14,
    icons: pbaccarat,
    title: "Mega Baccarat",
    titlebn: "এই সেক্সি",
    gameTypeCheck: "platForm",
    platForm: "PP",
    gameType: "LIVE",
    casinoType: "PP-LIVE-061",
  },
  {
    id: 15,
    icons: pdt,
    title: "Dragon Tiger",
    titlebn: "এই সেক্সি",
    gameTypeCheck: "platForm",
    platForm: "PP",
    gameType: "LIVE",
    casinoType: "PP-LIVE-042",
  },
  {
    id: 16,
    icons: ppbacarrat2,
    title: "Baccarat 2",
    titlebn: "এই সেক্সি",
    gameTypeCheck: "platForm",
    platForm: "PP",
    gameType: "LIVE",
    casinoType: "PP-LIVE-002",
  },
  {
    id: 17,
    icons: ppsicbo,
    title: "SicBo Deluxe",
    titlebn: "এই সেক্সি",
    gameTypeCheck: "platForm",
    platForm: "PP",
    gameType: "LIVE",
    casinoType: "PP-LIVE-003",
  },
  {
    id: 17,
    icons: pthilo,
    title: "Hi-Lo",
    titlebn: "এই সেক্সি",
    gameTypeCheck: "platForm",
    platForm: "PT",
    gameType: "LIVE",
    casinoType: "PT-LIVE-014",
  },
  {
    id: 17,
    icons: ptspinwin,
    title: "Spin a Win",
    titlebn: "এই সেক্সি",
    gameTypeCheck: "platForm",
    platForm: "PT",
    gameType: "LIVE",
    casinoType: "PT-LIVE-015",
  },
  {
    id: 17,
    icons: playtechteenpatti,
    title: "Teen Patti Live",
    titlebn: "এই সেক্সি",
    gameTypeCheck: "platForm",
    platForm: "PT",
    gameType: "LIVE",
    casinoType: "PT-LIVE-087",
  },

  {
    id: 18,
    icons: bgroll,
    title: "Roulette",
    titlebn: "এই সেক্সি",
    gameTypeCheck: "platForm",
    platForm: "BG",
    gameType: "LIVE",
    casinoType: "BG-LIVE-003",
  },
  {
    id: 19,
    icons: bgsicbo,
    title: "Sicbo",
    titlebn: "এই সেক্সি",
    gameTypeCheck: "platForm",
    platForm: "BG",
    gameType: "LIVE",
    casinoType: "BG-LIVE-004",
  },
  {
    id: 20,
    icons: bgspeedsicbo,
    title: "SpeedSicBo",
    titlebn: "এই সেক্সি",
    gameTypeCheck: "platForm",
    platForm: "BG",
    gameType: "LIVE",
    casinoType: "BG-LIVE-012",
  },
  {
    id: 20,
    icons: evosupersicbo,
    title: "Super Sic Bo",
    titlebn: "এই সেক্সি",
    gameTypeCheck: "platForm",
    platForm: "EVOLUTION",
    gameType: "LIVE",
    casinoType: "EVOLUTION-LIVE-005",
  },
  {
    id: 20,
    icons: pcrazytime,
    title: "Crazy Time",
    titlebn: "এই সেক্সি",
    gameTypeCheck: "platForm",
    platForm: "EVOLUTION",
    gameType: "LIVE",
    casinoType: "EVOLUTION-LIVE-006",
  },
  {
    id: 20,
    icons: pmonopoly,
    title: "MONOPOLY",
    titlebn: "এই সেক্সি",
    gameTypeCheck: "platForm",
    platForm: "EVOLUTION",
    gameType: "LIVE",
    casinoType: "EVOLUTION-LIVE-007",
  },
  {
    id: 20,
    icons: evolightdice,
    title: "Lightning Dice",
    titlebn: "এই সেক্সি",
    gameTypeCheck: "platForm",
    platForm: "EVOLUTION",
    gameType: "LIVE",
    casinoType: "EVOLUTION-LIVE-011",
  },
  {
    id: 20,
    icons: pcoinflip,
    title: "Crazy Coin Flip",
    titlebn: "এই সেক্সি",
    gameTypeCheck: "platForm",
    platForm: "EVOLUTION",
    gameType: "LIVE",
    casinoType: "EVOLUTION-LIVE-014",
  },
  {
    id: 20,
    icons: evoteenpatti,
    title: "Teen Patti",
    titlebn: "এই সেক্সি",
    gameTypeCheck: "platForm",
    platForm: "EVOLUTION",
    gameType: "LIVE",
    casinoType: "EVOLUTION-LIVE-026",
  },
  {
    id: 20,
    icons: evoligtroullete,
    title: "Lightning Roulette",
    titlebn: "এই সেক্সি",
    gameTypeCheck: "platForm",
    platForm: "EVOLUTION",
    gameType: "LIVE",
    casinoType: "EVOLUTION-LIVE-040",
  },
  {
    id: 20,
    icons: evospeedroullete,
    title: "Speed Roulette",
    titlebn: "এই সেক্সি",
    gameTypeCheck: "platForm",
    platForm: "EVOLUTION",
    gameType: "LIVE",
    casinoType: "EVOLUTION-LIVE-078",
  },


];
export const AuraCasinoData = [
  {
    image: "../assets/images/poker/aviator.webp",
    title: "Aviator",
    gameCode: "9999",
    legacy: "67722-1_8",
  },

  {
    image: "../assets/images/poker/avaitrix.webp",
    title: "Aviatrix",
    gameCode: "9999",
    legacy: "67722-2_8",
  },
  {
    image: "../assets/images/poker/1.webp",
    title: "7 up & 7 down",
    gameCode: "98789",
    legacy: "98789_2",
  },
  {
    image: "../assets/images/poker/2.webp",
    title: "Live Teenpati",
    gameCode: "56767",
    legacy: "56767_2",
  },
  {
    image: "../assets/images/poker/3.webp",
    title: "Teenpatti T20",
    gameCode: "56768",
    legacy: "56768_2",
  },
  {
    image: "../assets/images/poker/4.webp",
    title: "Dragon Tiger",
    gameCode: "98790",
    legacy: "98790_2",
  },
  {
    image: "../assets/images/poker/5.webp",
    title: "Hi Low",
    gameCode: "56968",
    legacy: "56968_2",
  },
  {
    image: "../assets/images/poker/6.webp",
    title: "Worli Matka",
    gameCode: "92037",
    legacy: "92037_2",
  },
  {
    image: "../assets/images/poker/9.webp",
    title: "Andhar Bahar",
  gameCode: "87564",
  legacy: "87564_2",
  },
  {
    image: "../assets/images/poker/10.webp",
    title: "Amar Akbar Anthony",
    gameCode: "98791",
    legacy: "98791_2",
  },
];
export const validationRules = {
  email: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
  password: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,15}$/,
  newPass: /^[a-zA-Z0-9]{8,15}$/,
  newPassMessage:
    "Password must contain uppercase and lowercase characters, numbers and must be minimum 8 character long (without special character).",
  passwordMessage:
    "Password must contain uppercase and lowercase characters, numbers, special character and must be minimum 8 character long.",
  characters: /^[a-zA-Z_ ]*$/,
  numbers: /^[0-9]*$/,
  removeWhitespace: /^[a-zA-Z0-9]+$/,
  numberNew: /^[0-9]*$/,
  numberWithDot: /^\d*(\.\d{0,10})?$/,
};
export const preventMaxInput = (e) => {
  e.target.value = e.target.value.trimStart();
  e.target.value = e.target.value.replace(/  +/g, " ");
};
export const AmountArray = [
  { id: 1, amount: 100 },
  { id: 2, amount: 500 },
  { id: 3, amount: 1000 },
  { id: 4, amount: 5000 },
  { id: 5, amount: 1000 },
  { id:6, amount: 15000 },
  { id: 7, amount: 20000 },
  { id: 8, amount: 25000 },
];